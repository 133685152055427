const $ = window.$ = window.jQuery = window.jQuery.noConflict();
const App = function() {

    return {
        init: () => {}
    };
}();

$(document).ready(() => {
    App.init();

    //Collapse Navbar When It's Clickicked
    $('body').click(function() {
        $('.navbar-collapse.show').collapse('hide');
    });

    var swiper = new Swiper('.swiper-container', {
        autoHeight: true,
        direction: 'horizontal',
        slidesPerView: 1,
        freeMode: false,
        freeModeFluid: true,
        grabCursor: false,
        mousewheel: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: true,
        },
        breakpoints: {
            768: {
                direction: 'horizontal',
                spaceBetween: 30,
            }
        }
    });

    // Contact Form
    var $contactForm  = $('#contact-form, #subscribe-form');

    $contactForm.submit(function() {
        var message;

        var $formAlert = $('#contact-form-alert');
        var $formAlertContainer = $('#contact-form-alert-container');
        
        $formAlertContainer.html('Hello World!');
        
        $.ajax({
            type: 'POST',
            url: $contactForm.attr('action'),
            data: $(this).serialize(),
            success: function(response) {
                var res = JSON.parse(response);

                if (res.status === true) {
                    message = res.message;
                } else {
                    message = res.message;
                }

                $formAlertContainer.html(message);
                $formAlert.modal({backdrop: true});
            }
        });
        
        return false;
    });
});